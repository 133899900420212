import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);

	useEffect(() => {
		// Check if we are in a browser environment before using window
		if (typeof window !== 'undefined') {
			// Access window safely
			window.location.href='/about';
		}
	}, []);
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"Home"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section>
			
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="flex-end"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});